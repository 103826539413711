import React, { useState, useEffect, useMemo } from 'react';
import { Search, RefreshCcw, ChevronDown, ChevronUp } from 'lucide-react';

const API_BASE_URL = 'https://watchlist-server-six.vercel.app/api';

const BiotechSearch = () => {
  const [companies, setCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [error, setError] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [trials, setTrials] = useState([]);
  const [isLoadingTrials, setIsLoadingTrials] = useState(false);

  const fetchCompanies = async (forceUpdate = false) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_BASE_URL}/${forceUpdate ? 'biotech/update' : 'biotech/companies'}`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      const processedCompanies = data.companies.map(company => ({
        ...company,
        displayMarketCap: company.marketCap,
        marketCap: parseFloat(company.marketCap.replace(/[^\d.-]/g, ''))
      }));
      setCompanies(processedCompanies || []);
      setLastUpdate(data.lastUpdate || new Date().toISOString());
    } catch (err) {
      setError(err.message === 'Failed to fetch' ? 
        'Unable to connect to server. Please check your connection.' : 
        err.message);
      setCompanies([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTrials = async (company) => {
    setIsLoadingTrials(true);
    setError(null);
    try {
      const response = await fetch(`${API_BASE_URL}/trials?company=${encodeURIComponent(company.name)}`);
      if (!response.ok) throw new Error('Failed to fetch trials');
      const data = await response.json();
      setTrials(data.studies || []);
    } catch (err) {
      setError('Failed to fetch clinical trials');
      setTrials([]);
    } finally {
      setIsLoadingTrials(false);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleCompanyClick = async (company) => {
    if (selectedCompany?.ticker === company.ticker) {
      setSelectedCompany(null);
      setTrials([]);
    } else {
      setSelectedCompany(company);
      await fetchTrials(company);
    }
  };

  const filteredCompanies = useMemo(() => {
    if (!searchTerm) return [];
    const search = searchTerm.toLowerCase();
    return companies
      .filter(company => 
        company.ticker.toLowerCase().startsWith(search) ||
        company.name.toLowerCase().includes(search)
      )
      .slice(0, 10);
  }, [companies, searchTerm]);

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <div className="mb-4">
        <div className="flex items-center gap-2 mb-2">
          <div className="flex-1 relative">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by ticker or company name..."
              className="w-full p-2 pr-10 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <Search className="absolute right-3 top-2.5 text-gray-400 h-5 w-5" />
          </div>
          <button
            onClick={() => fetchCompanies(true)}
            disabled={isLoading}
            className="p-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 disabled:bg-blue-300 flex items-center gap-2"
          >
            <RefreshCcw className={`h-5 w-5 ${isLoading ? 'animate-spin' : ''}`} />
            {isLoading ? 'Updating...' : 'Update'}
          </button>
        </div>

        {error && (
          <div className="mt-2 p-3 bg-red-50 border border-red-200 rounded-lg">
            <p className="text-red-600">{error}</p>
          </div>
        )}

        {lastUpdate && !error && (
          <p className="text-sm text-gray-500">
            Last updated: {new Date(lastUpdate).toLocaleString()}
          </p>
        )}
      </div>

      {searchTerm && (
        <div className="border rounded-lg divide-y">
          {filteredCompanies.map((company) => (
            <div key={company.ticker}>
              <div
                onClick={() => handleCompanyClick(company)}
                className="p-3 hover:bg-gray-50 cursor-pointer flex justify-between items-center"
              >
                <div>
                  <span className="font-medium">{company.ticker}</span>
                  <span className="mx-2 text-gray-400">•</span>
                  <span className="text-gray-600">{company.name}</span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-sm text-gray-500">
                    {company.displayMarketCap}
                  </span>
                  {selectedCompany?.ticker === company.ticker ? 
                    <ChevronUp className="h-4 w-4" /> : 
                    <ChevronDown className="h-4 w-4" />
                  }
                </div>
              </div>
              
              {selectedCompany?.ticker === company.ticker && (
                <div className="bg-gray-50 p-4">
                  <h3 className="font-medium mb-3">Clinical Trials</h3>
                  {isLoadingTrials ? (
                    <div className="text-center py-4">Loading trials...</div>
                  ) : trials.length > 0 ? (
                    <div className="space-y-3">
                      {trials.map((trial) => (
                        <div key={trial.nctId} className="bg-white p-3 rounded-lg shadow-sm">
                          <h4 className="font-medium">{trial.title}</h4>
                          <div className="mt-2 text-sm text-gray-600">
                            <div>Phase: {trial.phase || 'N/A'}</div>
                            <div>Status: {trial.status}</div>
                            <div>Start Date: {trial.startDate}</div>
                            <a 
                              href={`https://clinicaltrials.gov/study/${trial.nctId}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 hover:underline mt-1 inline-block"
                            >
                              View Details
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-gray-500">No clinical trials found</div>
                  )}
                </div>
              )}
            </div>
          ))}

          {filteredCompanies.length === 0 && (
            <div className="p-3 text-gray-500 text-center">
              No matches found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BiotechSearch;