import React, { useState } from 'react';
const ConditionSearch = () => {
  const [condition, setCondition] = useState('');
  const [trials, setTrials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const searchTrials = async (e) => {
    e.preventDefault();
    if (!condition.trim()) return;
    setLoading(true);
    setSearched(true);
    
    try {
      const response = await fetch(`https://watchlist-server-six.vercel.app/api/trials-by-condition?condition=${encodeURIComponent(condition)}`);
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to fetch trials');
      }
      setTrials(data.studies);
    } catch (error) {
      console.error('Error fetching trials:', error);
      setTrials([]);
    }
    setLoading(false);
  };
  return (
    <div className="max-w-6xl mx-auto p-4">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h1 className="text-2xl font-bold mb-6">Search Clinical Trials by Condition</h1>
        
        <form onSubmit={searchTrials} className="mb-8">
          <div className="flex gap-4">
            <input
              type="text"
              value={condition}
              onChange={(e) => setCondition(e.target.value)}
              placeholder="Enter a medical condition (e.g., diabetes, cancer)"
              className="flex-1 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            <button
              type="submit"
              className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Search
            </button>
          </div>
        </form>
        {loading ? (
          <div className="text-center text-gray-600">Loading trials...</div>
        ) : searched && trials.length === 0 ? (
          <div className="text-center text-gray-600">No trials found for this condition.</div>
        ) : (
          <div className="space-y-6">
            {trials.map((trial) => (
              <div 
                key={trial.nctId}
                className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition-shadow"
              >
                <div className="flex items-start justify-between gap-4">
                  <div className="flex-1">
                    <h2 className="font-semibold text-lg mb-2">{trial.title}</h2>
                    <div className="text-sm text-gray-600 space-y-1">
                      <div className="flex gap-2">
                        <span className="font-medium">NCT ID:</span>
                        <a 
                          href={`https://clinicaltrials.gov/study/${trial.nctId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:text-blue-800"
                        >
                          {trial.nctId}
                        </a>
                      </div>
                      <div>
                        <span className="font-medium">Sponsor:</span>
                        <span className="ml-2">{trial.sponsor}</span>
                      </div>
                      <div>
                        <span className="font-medium">Status:</span>
                        <span className="ml-2">{trial.status}</span>
                      </div>
                      <div>
                        <span className="font-medium">Phase:</span>
                        <span className="ml-2">{trial.phase}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default ConditionSearch;