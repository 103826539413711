import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import _ from 'lodash';

const RatioCharts = ({ incomeStatement, isQuarterly }) => {
  if (!incomeStatement) return null;

  const formatDate = (date) => {
    const d = new Date(date);
    return isQuarterly 
      ? `${d.getFullYear()} Q${Math.floor(d.getMonth() / 3) + 1}`
      : d.getFullYear().toString();
  };

  // Process data for margin charts
  const processMarginData = () => {
    const dates = Object.keys(incomeStatement.grossProfit || {}).sort();
    return dates.map(date => {
      const revenue = incomeStatement.totalRevenue?.[date] || 0;
      const grossProfit = incomeStatement.grossProfit?.[date] || 0;
      const operatingIncome = incomeStatement.operatingIncome?.[date] || 0;
      const netIncome = incomeStatement.netIncome?.[date] || 0;

      return {
        fullDate: date,
        displayDate: formatDate(date),
        'Gross Margin': ((grossProfit / revenue) * 100).toFixed(2),
        'Operating Margin': ((operatingIncome / revenue) * 100).toFixed(2),
        'Net Margin': ((netIncome / revenue) * 100).toFixed(2),
      };
    });
  };


  // Process data for revenue growth
  const processRevenueData = () => {
    const dates = Object.keys(incomeStatement.totalRevenue || {}).sort();
    const revenueData = [];
    
    for (let i = 1; i < dates.length; i++) {
      const currentDate = dates[i];
      const previousDate = dates[i-1];
      const currentRevenue = incomeStatement.totalRevenue[currentDate];
      const previousRevenue = incomeStatement.totalRevenue[previousDate];
      
      const growthRate = ((currentRevenue - previousRevenue) / previousRevenue) * 100;
      
      revenueData.push({
        fullDate: currentDate,
        displayDate: formatDate(currentDate),
        'Revenue Growth': growthRate.toFixed(2)
      });
    }
    
    return revenueData;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-2 border border-gray-200 rounded shadow-sm">
          <p className="text-sm font-medium">{label}</p>
          {payload.map((entry, index) => (
            <p key={index} className="text-sm" style={{ color: entry.color }}>
              {entry.name}: {entry.value}%
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="grid grid-cols-2 gap-6 p-6">
      {/* Margins Chart */}
      <div className="bg-white p-4 rounded-lg shadow-sm">
        <h3 className="text-lg font-medium mb-4">
          Profit Margins {isQuarterly ? '(Quarterly)' : '(Annual)'}
        </h3>
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={processMarginData()} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <XAxis dataKey="displayDate" />
              <YAxis tickFormatter={(value) => `${value}`} />
              <Tooltip content={<CustomTooltip />} />
              <Legend 
                verticalAlign="top" 
                height={36}
                iconType="circle"
              />
              <Bar dataKey="Gross Margin" fill="#60A5FA" />
              <Bar dataKey="Operating Margin" fill="#34D399" />
              <Bar dataKey="Net Margin" fill="#A78BFA" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Revenue Growth Chart */}
      <div className="bg-white p-4 rounded-lg shadow-sm">
        <h3 className="text-lg font-medium mb-4">
          {isQuarterly ? 'Q/Q Revenue Growth' : 'Y/Y Revenue Growth'}
        </h3>
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={processRevenueData()} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <XAxis dataKey="displayDate" />
              <YAxis tickFormatter={(value) => `${value}`} />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="Revenue Growth" fill="#F472B6" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      
    </div>
  );
};

export default RatioCharts;