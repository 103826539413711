import React, { useState, useEffect } from 'react';
import { Search, Star, X, Check, Filter } from 'lucide-react';

const BUSINESS_TYPES = [
  'Distribution',
  'Public Services',
  'B2B Services',
  'Manufacturing',
  'Retail',
  'Technology',
  'Healthcare',
  'Education'
];

const BusinessIdeas = () => {
  const [ideas, setIdeas] = useState([]);
  const [archivedIdeas, setArchivedIdeas] = useState([]);
  const [newIdea, setNewIdea] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [sortMethod, setSortMethod] = useState('date'); // 'date' or 'type'

  useEffect(() => {
    fetchIdeas();
  }, []);

  const fetchIdeas = async () => {
    try {
      const response = await fetch('https://watchlist-server-six.vercel.app/api/ideas');
      const data = await response.json();
      setIdeas(data.active || []);
      setArchivedIdeas(data.archived || []);
    } catch (error) {
      console.error('Error fetching ideas:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newIdea.trim() || !selectedType) return;

    const newIdeaObj = {
      id: Date.now(),
      name: newIdea,
      type: selectedType,
      stars: 1,
      date: new Date().toISOString(),
      archived: false
    };

    try {
      await fetch('https://watchlist-server-six.vercel.app/api/ideas', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newIdeaObj)
      });
      setIdeas([...ideas, newIdeaObj]);
      setNewIdea('');
      setSelectedType('');
    } catch (error) {
      console.error('Error adding idea:', error);
    }
  };

  const toggleStars = async (id) => {
    const updatedIdeas = ideas.map(idea => {
      if (idea.id === id) {
        const newStars = idea.stars === 3 ? 1 : idea.stars + 1;
        return { ...idea, stars: newStars };
      }
      return idea;
    });

    try {
      await fetch(`https://watchlist-server-six.vercel.app/api/ideas/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ stars: updatedIdeas.find(i => i.id === id).stars })
      });
      setIdeas(updatedIdeas);
    } catch (error) {
      console.error('Error updating stars:', error);
    }
  };

  const archiveIdea = async (id) => {
    const ideaToArchive = ideas.find(idea => idea.id === id);
    if (!ideaToArchive) return;

    try {
      await fetch(`https://watchlist-server-six.vercel.app/api/ideas/${id}/archive`, {
        method: 'PUT'
      });
      setIdeas(ideas.filter(idea => idea.id !== id));
      setArchivedIdeas([...archivedIdeas, { ...ideaToArchive, archived: true }]);
    } catch (error) {
      console.error('Error archiving idea:', error);
    }
  };

  const restoreIdea = async (id) => {
    const ideaToRestore = archivedIdeas.find(idea => idea.id === id);
    if (!ideaToRestore) return;

    try {
      await fetch(`https://watchlist-server-six.vercel.app/api/ideas/${id}/restore`, {
        method: 'PUT'
      });
      setArchivedIdeas(archivedIdeas.filter(idea => idea.id !== id));
      setIdeas([...ideas, { ...ideaToRestore, archived: false }]);
    } catch (error) {
      console.error('Error restoring idea:', error);
    }
  };

  const getStarColor = (starCount) => {
    if (starCount === 1) return 'text-red-500';
    if (starCount === 2) return 'text-yellow-500';
    return 'text-green-500';
  };

  const sortedIdeas = [...ideas].sort((a, b) => {
    if (sortMethod === 'alphabetical') {
      return a.name.localeCompare(b.name);
    } else if (sortMethod === 'type') {
      return a.type.localeCompare(b.type);
    }
    return new Date(b.date) - new Date(a.date);
  });

  const groupedIdeas = sortMethod === 'type'
    ? sortedIdeas.reduce((groups, idea) => {
        const group = groups[idea.type] || [];
        group.push(idea);
        groups[idea.type] = group;
        return groups;
      }, {})
    : { 'All Ideas': sortedIdeas };

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-sm">
        <div className="border-b border-gray-200 p-6">
          <form onSubmit={handleSubmit} className="flex gap-4">
            <input
              type="text"
              value={newIdea}
              onChange={(e) => setNewIdea(e.target.value)}
              placeholder="Enter new business idea..."
              className="flex-1 pl-3 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="">Select Type</option>
              {BUSINESS_TYPES.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
            <button
              type="submit"
              disabled={!newIdea || !selectedType}
              className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:bg-gray-300"
            >
              Add Idea
            </button>
          </form>
        </div>

        <div className="p-6">
          <div className="flex justify-end mb-4">
            <select
              value={sortMethod}
              onChange={(e) => setSortMethod(e.target.value)}
              className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="date">Sort by Date</option>
              <option value="alphabetical">Sort Alphabetically</option>
              <option value="type">Sort by Type</option>
            </select>
          </div>

          {Object.entries(groupedIdeas).map(([type, typeIdeas]) => (
            <div key={type} className="mb-8">
              <h2 className="text-lg font-semibold mb-4">{type}</h2>
              <div className="space-y-4">
                {typeIdeas.map(idea => (
                  <div key={idea.id} className="flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50">
                    <div className="flex-1">
                      <h3 className="font-medium">{idea.name}</h3>
                      <p className="text-sm text-gray-500">{new Date(idea.date).toLocaleDateString()}</p>
                    </div>
                    <div className="flex items-center gap-4">
                      <button
                        onClick={() => toggleStars(idea.id)}
                        className="flex items-center"
                      >
                        {[...Array(idea.stars)].map((_, i) => (
                          <Star
                            key={i}
                            className={`w-5 h-5 fill-current ${getStarColor(idea.stars)}`}
                          />
                        ))}
                      </button>
                      <button
                        onClick={() => archiveIdea(idea.id)}
                        className="text-red-500 hover:text-red-600"
                      >
                        <X className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}

          {archivedIdeas.length > 0 && (
            <div className="mt-12 border-t pt-8">
              <h2 className="text-lg font-semibold mb-4">Archived Ideas</h2>
              <div className="space-y-4">
                {archivedIdeas.map(idea => (
                  <div key={idea.id} className="flex items-center justify-between p-4 border rounded-lg bg-gray-50">
                    <div className="flex-1">
                      <h3 className="font-medium text-gray-500">{idea.name}</h3>
                      <p className="text-sm text-gray-400">{idea.type}</p>
                    </div>
                    <button
                      onClick={() => restoreIdea(idea.id)}
                      className="text-green-500 hover:text-green-600"
                    >
                      <Check className="w-5 h-5" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessIdeas;