import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import { Download, Search, LineChart } from 'lucide-react';
import RatioCharts from './RatioCharts';

const Valuations = () => {
  const [activeTab, setActiveTab] = useState('income');
  const [isQuarterly, setIsQuarterly] = useState(false);
  const [data, setData] = useState({
    incomeStatement: null,
    balanceSheet: null,
    cashFlow: null
  });
  const [ticker, setTicker] = useState('');
  const [inputTicker, setInputTicker] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cache, setCache] = useState({});
  
  

  // Load cache from localStorage on mount
  useEffect(() => {
    const savedCache = localStorage.getItem('financialDataCache');
    if (savedCache) {
      setCache(JSON.parse(savedCache));
    }
  }, []);

  const getCacheKey = (ticker, statement, period) => `${ticker}_${statement}_${period}`;

  const isDataCached = (ticker, statement, period) => {
    const cacheKey = getCacheKey(ticker, statement, period);
    const cachedData = cache[cacheKey];
    
    if (!cachedData) return false;
    
    const now = Date.now();
    const hoursSinceCache = (now - cachedData.timestamp) / (1000 * 60 * 60);
    return hoursSinceCache < 24;
  };

  const getEndpoint = (baseEndpoint) => {
    return isQuarterly ? `${baseEndpoint}Quarterly` : `${baseEndpoint}Annually`;
  };

  const fetchData = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');
      return await response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch data. Please check the ticker symbol and try again.');
      return null;
    }
  };

  const processData = (rawData) => {
    if (!rawData) return null;
    
    const df = {};
    rawData.forEach(row => {
      const date = row.fiscalDateEnding;
      delete row.fiscalDateEnding;
      Object.entries(row).forEach(([key, value]) => {
        if (!df[key]) df[key] = {};
        df[key][date] = isNaN(Number(value)) ? 0 : Number(value);
      });
    });
    return df;
  };

  const formatDate = (date) => {
    const d = new Date(date);
    return isQuarterly 
      ? `${d.getFullYear()} Q${Math.floor(d.getMonth() / 3) + 1}`
      : d.getFullYear().toString();
  };

  const formatNumber = (value) => {
    if (!value || isNaN(value)) return '0';
    const inMillions = value / 1000000;
    return new Intl.NumberFormat('en-US', { 
      minimumFractionDigits: 2,
      maximumFractionDigits: 2 
    }).format(inMillions);
  };

  const getCellColor = (value) => {
    if (!value || isNaN(value)) return '';
    return value < 0 ? 'text-red-500' : 'text-gray-900';
  };

  const handleTickerSubmit = async (e) => {
    e.preventDefault();
    if (inputTicker.trim() === '') return;
    
    setIsLoading(true);
    setError(null);
    setTicker(inputTicker.toLowerCase().trim());
    setData({
      incomeStatement: null,
      balanceSheet: null,
      cashFlow: null
    });
  };

  const exportToExcel = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const statements = [
        { name: 'Income Statement', key: 'incomeStatement', endpoint: 'incomeStatement' },
        { name: 'Balance Sheet', key: 'balanceSheet', endpoint: 'balanceSheet' },
        { name: 'Cash Flow', key: 'cashFlow', endpoint: 'cashFlow' }
      ];
      
      const allStatements = {};
      
      await Promise.all(statements.map(async (stmt) => {
        const periodType = isQuarterly ? 'Quarterly' : 'Annually';
        const cacheKey = getCacheKey(ticker, stmt.key, periodType);
        
        if (data[stmt.key]) {
          allStatements[stmt.key] = data[stmt.key];
          return;
        }
        
        if (isDataCached(ticker, stmt.key, periodType)) {
          allStatements[stmt.key] = cache[cacheKey].data;
          return;
        }
        
        const endpoint = getEndpoint(stmt.endpoint);
        const newData = await fetchData(`https://watchlist-server-six.vercel.app/api/${endpoint}/${ticker}`);
        const processedData = processData(newData);
        
        if (processedData) {
          const newCache = {
            ...cache,
            [cacheKey]: {
              data: processedData,
              timestamp: Date.now()
            }
          };
          setCache(newCache);
          localStorage.setItem('financialDataCache', JSON.stringify(newCache));
          
          allStatements[stmt.key] = processedData;
          
          setData(prev => ({
            ...prev,
            [stmt.key]: processedData
          }));
        }
      }));

      const workbook = XLSX.utils.book_new();
      const periodType = isQuarterly ? 'Quarterly' : 'Annual';

      statements.forEach(({ name: sheetName, key: dataKey }) => {
        const statementData = allStatements[dataKey];
        if (!statementData) return;

        const dates = Object.keys(Object.values(statementData)[0] || {})
          .sort((a, b) => new Date(a) - new Date(b));
        
        const sheetData = [
          ['Metric', ...dates.map(date => formatDate(date))]
        ];
        
        Object.entries(statementData).forEach(([metric, values]) => {
          const row = [_.startCase(metric)];
          dates.forEach(date => {
            const value = values[date] ? values[date] / 1000000 : 0;
            row.push(Number(value.toFixed(2)));
          });
          sheetData.push(row);
        });
        
        const ws = XLSX.utils.aoa_to_sheet(sheetData);
        
        const colWidths = [
          { wch: 30 },
          ...dates.map(() => ({ wch: 15 }))
        ];
        ws['!cols'] = colWidths;
        
        XLSX.utils.book_append_sheet(workbook, ws, `${sheetName} (${periodType})`);
      });

      XLSX.writeFile(workbook, `${ticker}_${periodType.toLowerCase()}_financials.xlsx`);
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      setError('Failed to export data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!ticker) return;

    const fetchTabData = async () => {
      setIsLoading(true);
      setError(null);
      
      let baseEndpoint = '';
      let dataKey = '';
      
      switch(activeTab) {
        case 'income':
          baseEndpoint = 'incomeStatement';
          dataKey = 'incomeStatement';
          break;
        case 'balance':
          baseEndpoint = 'balanceSheet';
          dataKey = 'balanceSheet';
          break;
        case 'cash':
          baseEndpoint = 'cashFlow';
          dataKey = 'cashFlow';
          break;
        case 'ratios':
          if (!data.incomeStatement) {
            baseEndpoint = 'incomeStatement';
            dataKey = 'incomeStatement';
          } else {
            setIsLoading(false);
            return;
          }
          break;
      }

      const periodType = isQuarterly ? 'Quarterly' : 'Annually';
      const cacheKey = getCacheKey(ticker, dataKey, periodType);

      if (isDataCached(ticker, activeTab, periodType)) {
        const cachedData = cache[cacheKey].data;
        setData(prev => ({
          ...prev,
          [dataKey]: cachedData
        }));
        setIsLoading(false);
        return;
      }

      if (baseEndpoint) {
        const endpoint = getEndpoint(baseEndpoint);
        const newData = await fetchData(`https://watchlist-server-six.vercel.app/api/${endpoint}/${ticker}`);
        const processedData = processData(newData);
        
        if (processedData) {
          const newCache = {
            ...cache,
            [cacheKey]: {
              data: processedData,
              timestamp: Date.now()
            }
          };
          setCache(newCache);
          localStorage.setItem('financialDataCache', JSON.stringify(newCache));
        }
        
        setData(prev => ({
          ...prev,
          [dataKey]: processedData
        }));
      }
      
      setIsLoading(false);
    };

    fetchTabData();
  }, [activeTab, ticker, isQuarterly]);

  const activeData = activeTab !== 'ratios' ? 
    data[activeTab === 'income' ? 'incomeStatement' : 
         activeTab === 'balance' ? 'balanceSheet' : 'cashFlow'] :
    null;

  const renderEmptyState = () => (
    <div className="flex flex-col items-center justify-center p-12 text-gray-500">
      <LineChart className="w-16 h-16 mb-4 text-gray-400" />
      <h3 className="text-lg font-medium mb-2">No Financial Data Loaded</h3>
      <p className="text-sm text-center mb-4">
        Please enter a ticker symbol in the search box above to view financial statements
      </p>
      <div className="flex items-center space-x-2 text-sm">
        <span className="px-2 py-1 bg-gray-100 rounded">Examples:</span>
        <span className="text-blue-500">AAPL</span>
        <span className="text-blue-500">MSFT</span>
        <span className="text-blue-500">GOOGL</span>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <div className="bg-white rounded-lg shadow-sm">
        <div className="border-b border-gray-200">
          <div className="flex justify-between items-center px-6 py-4">
            <div className="flex items-center space-x-4">
              <form onSubmit={handleTickerSubmit} className="flex items-center">
                <div className="relative">
                  <input
                    type="text"
                    value={inputTicker}
                    onChange={(e) => setInputTicker(e.target.value)}
                    placeholder="Enter ticker..."
                    className="pl-3 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                  <button
                    type="submit"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                  >
                    <Search className="w-5 h-5" />
                  </button>
                </div>
              </form>
              
              {/* Period Toggle */}
              <div className="flex items-center space-x-2">
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={isQuarterly}
                    onChange={() => setIsQuarterly(!isQuarterly)}
                    className="sr-only peer"
                  />
                  <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-500"></div>
                  <span className="ml-2 text-sm font-medium text-gray-500">
                    {isQuarterly ? 'Quarterly' : 'Annual'}
                  </span>
                </label>
              </div>

              <nav className="flex space-x-8">
                {[
                  { id: 'income', name: 'Income Statement' },
                  { id: 'balance', name: 'Balance Sheet' },
                  { id: 'cash', name: 'Cash Flow' },
                  { id: 'ratios', name: 'Ratios' }
                ].map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => setActiveTab(tab.id)}
                    className={`
                      py-2 px-1 -mb-px font-medium text-sm border-b-2 transition-colors
                      ${activeTab === tab.id 
                        ? 'border-blue-500 text-blue-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
                    `}
                  >
                    {tab.name}
                  </button>
                ))}
              </nav>
            </div>
            <button
              onClick={exportToExcel}
              disabled={!ticker}
              className={`
                inline-flex items-center px-4 py-2 text-sm font-medium rounded
                ${ticker 
                  ? 'bg-blue-500 text-white hover:bg-blue-600' 
                  : 'bg-gray-200 text-gray-500 cursor-not-allowed'}
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
              `}
            >
              <Download className="w-4 h-4 mr-2" />
              Export to Excel
            </button>
          </div>
        </div>

        {error && (
          <div className="p-4 text-red-500 text-center">
            {error}
          </div>
        )}

        {isLoading ? (
          <div className="animate-pulse p-8">
            <div className="space-y-4">
              <div className="h-4 bg-gray-200 rounded w-3/4"></div>
              <div className="space-y-2">
                <div className="h-4 bg-gray-200 rounded"></div>
                <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                <div className="h-4 bg-gray-200 rounded w-4/6"></div>
              </div>
            </div>
          </div>
        ) : !ticker ? (
          renderEmptyState()
        ) : activeTab === 'ratios' ? (
          <RatioCharts incomeStatement={data.incomeStatement} isQuarterly={isQuarterly} />
        ) : activeData ? (
          <div className="overflow-x-auto">
            <table className="w-full text-sm">
              <thead>
                <tr className="bg-gray-50">
                  <th className="sticky left-0 z-10 bg-gray-50 p-4 text-left font-medium text-gray-500">
                    Metric
                  </th>
                  {Object.keys(Object.values(activeData)[0] || {})
                    .sort((a, b) => new Date(b) - new Date(a))
                    .map(date => (
                      <th key={date} className="p-4 text-right font-medium text-gray-500">
                        {formatDate(date)}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {Object.entries(activeData).map(([metric, values], idx) => (
                  <tr key={metric} className="hover:bg-gray-50">
                    <td className="sticky left-0 z-10 bg-white p-4 font-medium text-gray-900 whitespace-nowrap">
                      {_.startCase(metric)}
                    </td>
                    {Object.entries(values)
                      .sort((a, b) => new Date(b[0]) - new Date(a[0]))
                      .map(([date, value]) => (
                        <td 
                          key={date} 
                          className={`p-4 text-right whitespace-nowrap ${getCellColor(value)}`}
                        >
                          {formatNumber(value)}
                        </td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        
        <div className="px-6 py-4 border-t border-gray-200">
          <p className="text-xs text-gray-500">* Values in millions (except for per share items) of USD</p>
        </div>
      </div>
    </div>
  );
};

export default Valuations;