import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { X } from 'lucide-react';
import './fonts.css';

axios.defaults.baseURL = 'https://watchlist-server-six.vercel.app';

function StockTickerPage() {
  const [tickers, setTickers] = useState([]);
  const [newTicker, setNewTicker] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState(null);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [modalAction, setModalAction] = useState(''); // 'add' or 'delete'

  useEffect(() => {
    fetchTickers();
  }, []);

  const fetchTickers = async () => {
    try {
      const { data } = await axios.get('/api/tickers');
      setTickers(data);
    } catch (error) {
      console.error('Error fetching tickers:', error);
    }
  };

  const handleAddClick = () => {
    if (!newTicker) return;
    setModalAction('add');
    setShowModal(true);
    setPassword('');
    setError('');
  };

  const handleAddTicker = async () => {
    try {
      await axios.post('/api/add-ticker', { 
        ticker: newTicker,
        password: password 
      });
      setNewTicker('');
      setShowModal(false);
      setPassword('');
      fetchTickers();
    } catch (error) {
      setError('Invalid password or failed to add ticker');
    }
  };

  const handleUpdateTickers = async () => {
    try {
      await axios.post('/api/update-tickers');
      fetchTickers();
    } catch (error) {
      console.error('Error updating tickers:', error);
    }
  };

  const handleDeleteClick = (ticker) => {
    setSelectedTicker(ticker);
    setModalAction('delete');
    setShowModal(true);
    setPassword('');
    setError('');
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`/api/ticker/${selectedTicker}`, {
        data: { password }
      });
      setShowModal(false);
      setSelectedTicker(null);
      setPassword('');
      fetchTickers();
    } catch (error) {
      setError('Invalid password or failed to delete ticker');
    }
  };

  const handleModalSubmit = () => {
    if (modalAction === 'add') {
      handleAddTicker();
    } else {
      handleConfirmDelete();
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setPassword('');
    setError('');
    setModalAction('');
  };

  return (
    <div className="container items-center justify-center mx-auto p-4">
      <h1 className="coprgtb text-2xl content-center font-bold mb-4">Stock Tickers</h1>

      <div className="mb-4">
        <input
          type="text"
          value={newTicker}
          onChange={(e) => setNewTicker(e.target.value.toUpperCase())}
          placeholder="Enter new ticker"
          className="border content-center  p-2 mr-2"
        />
        <button
          onClick={handleAddClick}
          className="bg-blue-500 text-white p-2 rounded"
        >
          Add Ticker
        </button>
      </div>
      
      <button
        onClick={handleUpdateTickers}
        className="bg-green-500 text-white p-2 rounded mb-4"
      >
        Update All Tickers
      </button>

      <table className="table-auto bg-green-50 w-full">
        <thead>
          <tr>
            <th className="px-4 py-2">Actions</th>
            <th className="px-4 py-2">Ticker</th>
            <th className="px-4 py-2">Name</th>
            <th className="px-4 py-2">Price</th>
            <th className="px-4 py-2">1d</th>
            <th className="px-4 py-2">5d</th>
            <th className="px-4 py-2">1m</th>
            <th className="px-4 py-2">1y</th>
            <th className="px-4 py-2">Date Added</th>
            <th className="px-4 py-2">Price Added</th>
            <th className="px-4 py-2">Added Change</th>
          </tr>
        </thead>
        <tbody>
          {tickers.map((ticker) => (
            <tr key={ticker.ticker}>
              <td className="border px-4 py-2">
                <button
                  onClick={() => handleDeleteClick(ticker.ticker)}
                  className="text-red-500 hover:text-red-700"
                >
                  <X size={20} />
                </button>
              </td>
              <td className="border px-4 py-2">{ticker.ticker}</td>
              <td className="border px-4 py-2">{ticker.name}</td>
              <td className="border px-4 py-2">{ticker.price}</td>
              <td className="border px-4 py-2">{ticker['1d']}%</td>
              <td className="border px-4 py-2">{ticker['5d']}%</td>
              <td className="border px-4 py-2">{ticker['1m']}%</td>
              <td className="border px-4 py-2">{ticker['1y']}%</td>
              <td className="border px-4 py-2">{new Date(ticker.date_added).toLocaleDateString()}</td>
              <td className="border px-4 py-2">{ticker.price_added}</td>
              <td className="border px-4 py-2">{ticker.added_change}%</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Password Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">
                {modalAction === 'add' ? 'Add New Ticker' : 'Delete Ticker'}
              </h3>
              <button 
                onClick={closeModal}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={20} />
              </button>
            </div>
            
            <p className="mb-4">
              {modalAction === 'add' 
                ? `Enter password to add ${newTicker}`
                : `Enter password to delete ${selectedTicker}`}
            </p>
            
            {error && (
              <p className="text-red-500 mb-4">{error}</p>
            )}
            
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full border p-2 mb-4 rounded"
              placeholder="Enter password"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleModalSubmit();
                }
              }}
            />
            
            <div className="flex justify-end space-x-2">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleModalSubmit}
                className={`px-4 py-2 text-white rounded ${
                  modalAction === 'add' 
                    ? 'bg-blue-500 hover:bg-blue-600' 
                    : 'bg-red-500 hover:bg-red-600'
                }`}
              >
                {modalAction === 'add' ? 'Add' : 'Delete'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StockTickerPage;