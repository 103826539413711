// App.js
import React from 'react';
import StockTickerPage from './StockTickerPage';
import CollageComponent from './CollageComponent';
import CollageComponent2 from './CollageComponent2';
import Valuations from './Valuations';
import BiotechSearch from './BiotechSearch';
import ClinicalTrialsViewer from './ClinicalTrialsViewer';
import ConditionSearch from './ConditionSearch';
import HTMLHighlighter from './HTMLHighlighter';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<StockTickerPage />} />
        <Route path="/collage" element={<CollageComponent />} />
        <Route path="/collage2" element={<CollageComponent2 />} />
        <Route path="/valuation" element={<Valuations />} />
        <Route path="/bio" element={<BiotechSearch />} />
        <Route path="/ClinicalTrialsViewer" element={<ClinicalTrialsViewer />} />
        <Route path="/ConditionSearch" element={<ConditionSearch />} />
        <Route path="/html" element={<HTMLHighlighter />} />
      </Routes>
    </Router>
  );
};

export default App;
