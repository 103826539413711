import React, { useState, useRef, useEffect } from 'react';

const HTMLHighlighter = () => {
  const [htmlContent, setHtmlContent] = useState('');
  const [selectedText, setSelectedText] = useState('');
  const [showHighlightButton, setShowHighlightButton] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ y: 0 });
  const [originalFilename, setOriginalFilename] = useState('');
  const contentRef = useRef(null);
  const fileInputRef = useRef(null);
  const saveInputRef = useRef(null);

  const processExistingHighlights = () => {
    if (!contentRef.current) return;

    // Find all highlighted spans
    const highlights = contentRef.current.querySelectorAll('.bg-yellow-200');
    
    highlights.forEach(highlight => {
      // Add necessary classes if they don't exist
      highlight.classList.add('group', 'relative', 'cursor-pointer');
      
      // Only add remove button if it doesn't already exist
      if (!highlight.querySelector('.group-hover\\:inline-block')) {
        const removeButton = document.createElement('span');
        removeButton.className = 'absolute hidden group-hover:inline-block top-0 -right-8 bg-red-500 text-white w-6 h-6 text-center leading-6 cursor-pointer';
        removeButton.textContent = '×';
        removeButton.onclick = removeHighlight;
        highlight.appendChild(removeButton);
      }
    });
  };

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setOriginalFilename(file.name);
      const text = await file.text();
      setHtmlContent(text);
      
      // Process highlights after content is rendered
      setTimeout(processExistingHighlights, 0);
    }
  };

  const handleFileDrop = async (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setOriginalFilename(file.name);
      const text = await file.text();
      setHtmlContent(text);
      
      // Process highlights after content is rendered
      setTimeout(processExistingHighlights, 0);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleTextSelection = (e) => {
    const selection = window.getSelection();
    const selectedText = selection.toString().trim();
    
    if (selectedText.length > 0) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const contentRect = contentRef.current.getBoundingClientRect();
      const y = rect.top - contentRect.top;
      
      setButtonPosition({ y: Math.max(0, y) });
      setShowHighlightButton(true);
      setSelectedText(selectedText);
      
      e.stopPropagation();
    }
  };

  const applyHighlight = (e) => {
    e.stopPropagation();
    const selection = window.getSelection();
    if (selection.toString().length > 0) {
      const range = selection.getRangeAt(0);
      const span = document.createElement('span');
      span.className = 'bg-yellow-200 group relative cursor-pointer';
      
      try {
        range.surroundContents(span);
        
        const removeButton = document.createElement('span');
        removeButton.className = 'absolute hidden group-hover:inline-block top-0 -right-8 bg-red-500 text-white w-6 h-6 text-center leading-6 cursor-pointer';
        removeButton.textContent = '×';
        removeButton.onclick = removeHighlight;
        span.appendChild(removeButton);
        
        selection.removeAllRanges();
      } catch (e) {
        console.error('Could not highlight selection:', e);
      }
    }
    setShowHighlightButton(false);
  };

  const removeHighlight = (e) => {
    e.stopPropagation();
    const highlightSpan = e.target.parentNode;
    const parent = highlightSpan.parentNode;
    
    while (highlightSpan.firstChild) {
      if (highlightSpan.firstChild !== e.target) {
        parent.insertBefore(highlightSpan.firstChild, highlightSpan);
      } else {
        highlightSpan.removeChild(highlightSpan.firstChild);
      }
    }
    
    parent.removeChild(highlightSpan);
  };

  const prepareContentForSave = () => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = contentRef.current.innerHTML;
    
    // Remove only the delete buttons while preserving highlights
    tempDiv.querySelectorAll('.group-hover\\:inline-block').forEach(button => {
      if (button.parentNode) {
        button.parentNode.removeChild(button);
      }
    });

    const styleTag = `
      <style>
        .bg-yellow-200 {
          background-color: #fef08a;
        }
      </style>
    `;
    
    return styleTag + tempDiv.innerHTML;
  };

  const saveFile = () => {
    const content = prepareContentForSave();
    const blob = new Blob([content], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    // Use the original filename, or fallback to a default name
    a.download = originalFilename || 'highlighted-content.html';
    a.click();
    URL.revokeObjectURL(url);
  };

  // Process existing highlights after component updates
  useEffect(() => {
    if (htmlContent) {
      processExistingHighlights();
    }
  }, [htmlContent]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.target.closest('.highlight-button')) {
        setShowHighlightButton(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileSelect}
          className="hidden"
          accept=".html,.htm"
        />
        
        {!htmlContent ? (
          <div 
            onClick={() => fileInputRef.current.click()}
            onDrop={handleFileDrop}
            onDragOver={handleDragOver}
            className="border-2 border-dashed border-gray-300 rounded-lg p-12 text-center cursor-pointer hover:border-gray-400"
          >
            <div className="mb-4">📂</div>
            <p className="text-gray-500">Click here or drag and drop your HTML file</p>
          </div>
        ) : (
          <div className="space-y-4">
            <div className="relative flex">
              <div className="w-8 relative">
                {showHighlightButton && (
                  <button
                    className="highlight-button absolute bg-yellow-400 hover:bg-yellow-500 w-6 h-6 rounded flex items-center justify-center shadow-lg z-50"
                    style={{ top: `${buttonPosition.y}px` }}
                    onClick={applyHighlight}
                  >
                    ✨
                  </button>
                )}
              </div>
              
              <div 
                ref={contentRef}
                onMouseUp={handleTextSelection}
                className="flex-grow prose max-w-none p-4 border rounded-lg min-h-[300px] bg-white relative"
                dangerouslySetInnerHTML={{ __html: htmlContent }}
              />
              
              <div className="w-8"></div>
            </div>
            
            <div className="flex justify-between items-center">
              <span className="text-gray-500">Original file: {originalFilename}</span>
              <button 
                onClick={saveFile}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center gap-2"
              >
                <span>💾</span>
                Save Highlighted File
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HTMLHighlighter;